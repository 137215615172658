nav ul a:visited {
    color: var(--primary-text);
    text-decoration: none;
}

.default-link {
    border-bottom: 1px solid transparent;
}

.active-link {
    border-bottom: 1px solid var(--primary-color);
}