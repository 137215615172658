*{
    /*outline: 1px solid gray;*/
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Iceland', cursive;
    color: var(--primary-text);
    background: var(--main-bg-color);
}
:root {
    --main-bg-color: #000003;
    --primary-color: #349638;
    --primary-text:#FFFFFF;
}