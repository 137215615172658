@import url("https://fonts.googleapis.com/css2?family=Big+Shoulders+Display&family=Iceland&family=Outfit:wght@400;700&display=swap");

.container{
    /*outline: 1px solid red;*/
    display: flex;
    flex-wrap: wrap;
    flex-grow: 0;
    flex-shrink: 1;
    justify-content: center;
    background: linear-gradient(59deg, var(--main-bg-color) 85%,red 120%) ;

}
.container article {
    /*outline: 1px solid blue;*/
    display: flex;
    flex-direction: row;
    width: 60%;
    height: 644px;
    align-items: center;
    justify-content: center;
    gap: 1em;
}
.hi{
    /*outline: 2px solid #61dafb;*/
    color: var(--primary-color);
    display: flex;
    font-family: 'Big Shoulders Display',sans-serif;
    font-style: normal;
    font-weight: 743;
    font-size: 538px;
    line-height: 644px;
    padding-right: 50px;
    margin-right: 50px;

    letter-spacing: -0.06em;
    text-transform: uppercase;
}
.welcome{
    font-family: 'Iceland',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 25px;
    letter-spacing: 0.075em;
    text-transform: uppercase;
}
.gap{
    display: flex;
    flex-direction: column;
    gap: 2em;
}
.text{
    font-family: 'Iceland',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    /* or 26px */

    letter-spacing: 0.075em;
    text-transform: uppercase;
}
.button{
    color: var(--primary-color);
    font-family: 'Big Shoulders Display',sans-serif;
    font-style: normal;
    font-weight: 743;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.075em;
    text-transform: uppercase;
    border: 2px solid var(--primary-color);
    padding: 10px 50px;
    display: flex;
    align-self: flex-start;
}